<template>
  <div class="slider-container">
    <h2 class="slider-title">Отзывы наших пользователей</h2>
    <swiper
        :slides-per-view="1"
        :loop="true"
        :autoplay="{ delay: 3000 }"
        :pagination="pagination"
        :navigation="true"
        class="swiper-container"
    >
      <swiper-slide v-for="(review, index) in reviews" :key="index">
        <div class="slide">
          <p class="review-text">{{ review.text }}</p>
          <div class="review-rating">
            <span v-for="n in 5" :key="n" class="star" :class="{ active: n <= review.rating }">★</span>
          </div>
          <p class="review-author">{{ review.author }}</p>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

// Инициализация модулей Swiper
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default {
  name: 'Slider',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      reviews: [
        {
          text: 'Отличный сервис, очень доволен!',
          rating: 5,
          author: 'Иван Иванов'
        },
        {
          text: 'Хороший интерфейс и много полезной информации.',
          rating: 4,
          author: 'Мария Смирнова'
        },
        {
          text: 'Помогли с выбором общежития, благодарю!',
          rating: 5,
          author: 'Алексей Петров'
        }
        // Добавьте больше отзывов при необходимости
      ]
    };
  }
};
</script>

<style scoped>
.slider-container {
  padding: 2rem;
  background-color: #f8f8f8;
  text-align: center;
  border-radius: 10px;
  background-color: #e0e0e0;
}

.slider-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #0044cc;
}

.swiper-container {
  width: 80%;
  margin: 0 auto;
}

.slide {
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.review-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.review-rating {
  margin-bottom: 1rem;
}

.star {
  font-size: 1.5rem;
  color: #ddd;
}

.star.active {
  color: #ffc107;
}

.review-author {
  font-size: 1rem;
  color: #666;
}

/* Добавление стилей для стрелок навигации */
.swiper-button-next,
.swiper-button-prev {
  color: #0044cc;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #003399;
  background-color: rgba(255, 255, 255, 1);
}
</style>
