<template>
  <section>
    <!-- Отзывы будут здесь -->
  </section>
</template>

<script>
export default {
  name: 'Reviews',
};
</script>

<style scoped>
/* Стили для Reviews */
</style>
