<template>
  <div class="info-team">
    <div class="main-frame">
      <div class="inner-frame">
        <h1 class="title">Команда DorMentor</h1>
        <p class="description">
          Мы не понаслышке знаем каково жить в общежитии, поэтому делимся нашим опытом и предлагаем сервисы, чтобы
          упростить твое проживание на время обучения.
        </p>
      </div>
      <img src="@/assets/about/InfoTeam/BlackLogo.svg" alt="BlackLogo" class="black-logo"/>
      <img src="@/assets/about/InfoTeam/BlueLogo.svg" alt="BlueLogo" class="blue-logo"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoTeam'
}
</script>

<style scoped>
.info-team {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh; /* не прилипаем к хедеру и другим компонентам */
  padding-top: 3.5vh;
}

.main-frame {
  width: 75vh;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.inner-frame {
  width: 55vh;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 5vh;
  font-weight: 700;
  line-height: 5vh;
  text-align: center;
  margin-bottom: 2vh;
}

.description {
  font-size: 3vh;
  font-weight: 400;
  line-height: 3.5vh;
  text-align: center;
}

.black-logo {
  position: absolute; /* абсолюты для расположения логотипов */
  bottom: 0;
  left: 0;
  width: 10vh;
  height: auto;
}

.blue-logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 10vh;
  height: auto;
}
</style>