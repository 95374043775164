<template>
  <div class="page-container">
    <InfoTeam />
    <WhatWeOffer />
    <ContactUs />
  </div>
</template>

<script>
import InfoTeam from '@/components/about/InfoTeam.vue';
import WhatWeOffer from '@/components/about/WhatWeOffer.vue';
import ContactUs from '@/components/about/ContactUs.vue'

export default {
  name: 'AboutPage',
  components: {
    InfoTeam,
    WhatWeOffer,
    ContactUs,
  }
}
</script>

<style scoped>
.page-container {
  background-color: #f7f8fd;
}
</style>