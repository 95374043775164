<template>
  <div class="dorms-page">
    <div class="main-frame">
      <h1 class="dorms-title">Общежития</h1>
      <div class="inner-frame">
        <p class="select-city-text">Выберите город и ВУЗ, чтобы узнать информацию об общежитии</p>
        <div class="select-frame">
          <div class="select-container">
            <label for="city-select" class="select-label">Город</label>
            <select id="city-select" class="city-select" v-model="selectedCity" @change="updateUniversity">
              <option v-for="city in cities" :key="city.id" :value="city.id" class="select-option">{{ city.name }}</option>
            </select>
          </div>
          <div class="select-container">
            <label for="university-select" class="select-label">Высшее учебное заведение</label>
            <select id="university-select" class="university-select" v-model="selectedUniversity" @change="emitSelectedUniversity">
              <option v-for="university in filteredUniversities" :key="university.id" :value="university.id" class="select-option">{{ university.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectorsDorms',
  data() {
    return {
      cities: [],
      universities: [],
      selectedCity: null,
      selectedUniversity: null,
    };
  },
  computed: {
    filteredUniversities() {
      return this.universities.filter(university => university.cityId === this.selectedCity);
    }
  },
  watch: {
    selectedCity(newCity) {
      this.updateUniversity(newCity);
    }
  },
  mounted() {
    this.loadCities();
    this.loadUniversities();
  },
  methods: {
    loadCities() {
      // Загрузка данных о городах (пример данных, замените на реальный API вызов)
      this.cities = [
        { id: 'city1', name: 'Санкт-Петербург' },
        { id: 'city2', name: 'Ростов-на-Дону' },
        { id: 'city3', name: 'Москва' },
      ];
      this.selectedCity = this.cities[0].id; // Установка изначально выбранного города
    },
    loadUniversities() {
      // Загрузка данных о университетах (пример данных, замените на реальный API вызов)
      this.universities = [
        { id: 'university1', name: 'ИТМО', cityId: 'city1' },
        { id: 'university2', name: 'СПбГУ', cityId: 'city1' },
        { id: 'university3', name: 'ЮУ ИУБиП', cityId: 'city2' },
        { id: 'university4', name: 'ЮФУ', cityId: 'city2' },
        { id: 'university5', name: 'МГУ', cityId: 'city3' },
      ];
      this.selectedUniversity = this.filteredUniversities[0]?.id; // Установка изначально выбранного университета
    },
    updateUniversity() {
      const universitiesForSelectedCity = this.filteredUniversities;
      if (universitiesForSelectedCity.length > 0) {
        this.selectedUniversity = universitiesForSelectedCity[0].id;
        this.emitSelectedUniversity();
      } else {
        this.selectedUniversity = null;
      }
    },
    emitSelectedUniversity() {
      this.$emit('update-dormitories', this.selectedCity, this.selectedUniversity);
    }
  }
};
</script>

<style scoped>
.dorms-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  padding-top: 4vh;
}

.main-frame {
  width: 60%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6vh;
}

.dorms-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 8vh;
  font-weight: 700;
  text-align: center;
}

/* Внутренний фрейм с селекторами */
.inner-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4vh;
}

/* Текст подсказки для выбора города и ВУЗа */
.select-city-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 3.5vh;
  font-weight: 400;
  text-align: center;
}

.select-frame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4vh;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

/* Селекторы города и ВУЗа */
.city-select, .university-select {
  width: 100%;
  height: 8vh;
  padding: 1vh 2vh;
  border-radius: 1vh;
  font-size: 2.5vh;
  font-weight: 400;
  color: #000000;
  box-shadow: 0px 0px 1.5vh 0px #0000001A;
  border: none;
  text-align: left;
}

/* Опции селекторов */
.select-option {
  font-size: 2.5vh;
  font-weight: 400;
}

.select-label {
  font-size: 2.5vh;
  font-weight: 400;
  flex-wrap: nowrap;
  color: #808080;
}

/* Использование em для размеров, чтобы было ближе к дизайну */
.city-select, .university-select {
  width: 15em;
  height: 3.25em;
  padding: 1em 1em 1em 1em;
  border-radius: 0.5em;
}
</style>
