import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast from './plugins/toast';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'; // Подключение темы
import 'primevue/resources/primevue.min.css';           // Основные стили PrimeVue
import 'primeicons/primeicons.css';                     // Иконки
import 'primeflex/primeflex.css';                       // PrimeFlex для CSS-утилит
import './assets/main.css';                             // Пользовательские стили

const app = createApp(App);

Toast(app);

app.use(router).use(PrimeVue).use(store).mount('#app');