<template>
  <div class="dormitory-page">
    <div class="main-content">
      <div class="image-gallery">
        <div class="thumbnail-gallery">
          <img
              v-for="(image, index) in dormitory.images"
              :src="image"
              :key="index"
              @click="selectImage(index)"
              class="thumbnail"
          />
        </div>
        <img :src="selectedImage" alt="Selected Image" class="selected-image"/>
      </div>
      <div class="dormitory-info">
        <h1 class="dormitory-title">{{ dormitory.name }}</h1>
        <p class="dormitory-address"><strong>Адрес:</strong> {{ dormitory.address }}</p>
        <p class="dormitory-description">{{ dormitory.description }}</p>
      </div>
    </div>
    <div class="additional-info">
      <div class="dormitory-reviews">
        <h2>Отзывы</h2>
        <div v-for="(review, index) in dormitory.reviews" :key="index" class="review">
          <p class="review-text">{{ review.text }}</p>
          <div class="review-rating">
            <span v-for="n in 5" :key="n" class="star" :class="{ active: n <= review.rating }">★</span>
          </div>
          <p class="review-author">— {{ review.author }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/axios";

export default {
  name: 'DormitoryPage',
  data() {
    return {
      dormitory: {},
      selectedImage: require('@/assets/users-icon.png'),
    };
  },
  async created() {
    const {id} = this.$route.params;
    try {
      const response = await apiClient.get(`/dorms/${id}`);
      this.dormitory = response.data;
      this.dormitory.images = [
        require('@/assets/hse.png'),
        require('@/assets/itmo.png'),
        require('@/assets/logo.png'),
      ]
      this.selectedImage = require('@/assets/users-icon.png');
      // this.mainImage = this.dormitory.images ? this.dormitory.images[0] : '';
    } catch (error) {
      console.error('Ошибка при загрузке данных об общежитии', error);
    }
  },
  methods: {
    selectImage(index) {
      this.selectedImage = this.dormitory.images[index];
    },
  },
};
</script>

<style scoped>
.dormitory-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.main-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.image-gallery {
  display: flex;
  align-items: center;
}

.thumbnail-gallery {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 1rem;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail:hover,
.thumbnail:focus {
  border: 2px solid #0044cc;
}

.selected-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.dormitory-info {
  width: 60%;
}

.dormitory-title {
  font-size: 2.5rem;
  color: #0044cc;
}

.dormitory-address,
.dormitory-description {
  font-size: 1.2rem;
  color: #666;
  margin-top: 1rem;
}

.additional-info {
  width: 100%;
}

.dormitory-reviews {
  margin-top: 2rem;
}

.review {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.review-text {
  font-size: 1.2rem;
}

.review-rating {
  margin-top: 0.5rem;
}

.star {
  font-size: 1.5rem;
  color: #ddd;
}

.star.active {
  color: #ffc107;
}

.review-author {
  font-size: 1rem;
  color: #666;
  margin-top: 0.5rem;
}
</style>