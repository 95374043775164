<template>
  <div class="card">
    <div class="card-image-container">
      <img :src="image" alt="University Image" class="card-image"/>
    </div>
    <div class="flex-spacer"></div>
    <div class="card-content">
      <h3 class="card-title">{{ name }}</h3>
      <RedirectButton :to="`/dorms`" :city-id="cityId" :university-id=universityId />
    </div>
  </div>
</template>

<script>
import RedirectButton from "@/components/button/RedirectButton.vue";

export default {
  name: 'UniversityCard',
  components: {RedirectButton},
  props: {
    image: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    cityId: {
      type: String,
      required: true
    },
    universityId: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s;
  width: 300px;
  height: 400px; /* Устанавливаем фиксированную высоту для всех карточек */
}

.card:hover {
  transform: translateY(-10px);
}

.card-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ограничиваем высоту контейнера изображения */
}

.card-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Сохраняем пропорции изображения */
  border-radius: 10px;
}

.flex-spacer {
  flex-grow: 1;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Выравниваем элементы по низу */
  align-items: center;
}

.card-title {
  font-size: 1.5rem;
  margin: 1rem 0;
}
</style>
