import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'http://localhost:8088/api',
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor
apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('jwt');
        if (token) {
            console.log(token)
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default apiClient;
