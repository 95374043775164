<template>
  <div class="article">
    <img :src="article.image" alt="Article Image" class="article-image"/>
    <h1 class="article-title">{{ article.title }}</h1>
    <div class="article-content" v-html="article.content"></div>
  </div>
</template>

<script>
export default {
  name: 'Article',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.article {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica', sans-serif;
}

.article-image {
  width: 80%; /* Уменьшение ширины изображения */
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.article-title {
  font-size: 28px;
  margin-bottom: 20px;
}

.article-content h3 {
  font-size: 22px;
  margin-top: 20px;
}

.article-content p {
  font-size: 16px;
  line-height: 1.5;
}
</style>
