<template>
  <div class="dormitory-list">
    <div class="table-container">
      <div class="dormitory-table">
        <div class="header-row">
          <div class="header-cell first">
            <div class="header-text">Общежитие</div>
          </div>
          <div class="header-cell">
            <div class="header-text">Адрес</div>
          </div>
          <div class="header-cell">
            <div class="header-text">Количество жителей</div>
          </div>
          <div class="header-cell last">
            <div class="header-text">Ссылка</div>
          </div>
        </div>
        <div class="table-body">
          <div v-for="dormitory in dormitories" :key="dormitory.id" class="table-row">
            <div class="table-cell">{{ dormitory.name }}</div>
            <div class="table-cell border-left">{{ dormitory.address }}</div>
            <div class="table-cell border-left">{{ dormitory.residents }} человек</div>
            <div class="table-cell border-left">
              <RedirectButton :to="'/dorms'" :cityId="dormitory.cityId" :universityId="dormitory.universityId" text="Перейти" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RedirectButton from "@/components/button/RedirectButton.vue";

export default {
  name: 'DormList',
  components: { RedirectButton },
  props: {
    dormitories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.dormitory-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5vh;
}

.table-container {
  width: 95%;
  max-height: auto; /* таблица адаптируется под количество содержимого */
  background-color: #fff;
  border-radius: 1vh;
  overflow: hidden;
  margin-bottom: 2vh;
}

.dormitory-table {
  width: auto;
  display: flex;
  flex-direction: column;
  table-layout: auto;
}

.header-row, .table-row {
  display: flex;
  width: 100%;
}

.header-cell, .table-cell {
  flex: 1;
  padding: 0.5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-cell {
  height: 13vh;
  position: relative;
}

.header-text {
  font-size: 3vh;
  font-weight: 400;
  line-height: 1.38;
  position: relative;
  z-index: 1; /* текст поверх фигуры */
}

/* Фон заголовка */
.header-cell::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #426CF51A;
  border-radius: 0;
}

/* Первая ячейка заголовка */
.first::before {
  border-radius: 1vh 0 0 0;
}

/* Последняя ячейка заголовка */
.last::before {
  border-radius: 0 1vh 0 0;
}

.table-row {
  height: 19vh;
}

.table-cell {
  font-family: Helvetica, sans-serif;
  font-size: 3vh;
  font-weight: 400;
  line-height: 1.38;
}

/* Левая граница ячейки */
.border-left {
  border-left: 1px solid #ddd;
}
</style>
