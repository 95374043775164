<template>
  <div class="grid p-justify-between p-align-center contact-us">
    <div class="col p-md-6 contact-form-section">
      <div class="contact-form-section-div">
        <h1 class="title">Свяжитесь с нами</h1>
        <p class="description">Обращайтесь к нам по любым вопросам. Ответим на все!</p>
        <form class="p-fluid">
          <div class="p-field">
            <label for="name" class="p-sr-only">Имя</label>
            <InputText id="name" v-model="name" placeholder="Имя" class="input-field" />
          </div>
          <div class="p-field">
            <label for="message" class="p-sr-only">Текст сообщения</label>
            <Textarea id="message" v-model="message" placeholder="Текст сообщения" rows="5" autoResize class="textarea-field" />
          </div>
          <div class="p-field">
            <label for="email" class="p-sr-only">Почта</label>
            <InputText id="email" v-model="email" placeholder="Почта" class="input-field" />
          </div>
          <div class="p-field p-mt-4">
            <RedirectButton :href="'mailto:Dormentor@gmail.com'" text="Отправить" />
          </div>
        </form>
      </div>
      <div class="contact-info p-mt-5">
        <a href="https://t.me/Dormentor" class="contact-item">
          <img src="@/assets/about/ContactUs/TelegramIcon.svg" alt="TelegramIcon" class="icon-contact"/>
          <span class="contact-text">Dormentor</span>
        </a>
        <a href="https://vk.com/dormentor" class="contact-item">
          <img src="@/assets/about/ContactUs/VkIcon.svg" alt="VkIcon" class="icon-contact"/>
          <span class="contact-text">Dormentor</span>
        </a>
        <a href="mailto:Dormentor@gmail.com" class="contact-item">
          <img src="@/assets/about/ContactUs/EmailIcon.svg" alt="EmailIcon" class="icon-contact"/>
          <span class="contact-text">Dormentor@gmail.com</span>
        </a>
      </div>
    </div>
    <div class="col p-md-6 image-section">
      <img src="@/assets/about/ContactUs/IconContact.svg" alt="IconContact" class="contact-image"/>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import RedirectButton from '@/components/button/RedirectButton.vue';

export default {
  name: 'ContactUs',
  components: {
    InputText,
    Textarea,
    RedirectButton,
  },
  setup() {
    const name = ref('');
    const message = ref('');
    const email = ref('');
    return { name, message, email };
  },
};
</script>

<style scoped>
.contact-us {
  padding: 2rem;
}

.contact-form-section,
.image-section {
  padding: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.contact-form-section-div {
  padding: 0 1.5rem 1.5rem;
}

.p-field {
  margin-bottom: 0.3rem; /* Отступ между полями формы */
}

.input-field,
.textarea-field {
  padding: 1rem;
  font-size: 1rem;
}

.contact-info {
  margin-top: 2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
}

.icon-contact {
  width: 2rem;
  height: 2rem;
}

.contact-text {
  font-size: 1rem;
}

.p-field.p-mt-4 {
  margin-top: 1rem; /* Увеличенный отступ для кнопки */
}

.contact-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .contact-us {
    flex-direction: column;
  }

  .contact-form-section, .image-section {
    text-align: center;
    align-items: center;
  }

  .contact-image {
    margin-top: 2rem;
  }
}
</style>
