<template>
  <nav>
    <!-- Навигация будет здесь -->
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
};
</script>

<style scoped>
/* Стили для Navbar */
</style>