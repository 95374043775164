<template>
  <div class="main">
    <!--    <Slider />-->
    <Reviews/>
    <MainBanner/>
    <Metrics/>
    <Universities/>
    <Articles/>
    <SubscribeCard/>
  </div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Header from '@/components/hat/Header.vue';
import Reviews from '@/components/Reviews.vue';
import Metrics from '@/components/Metrics.vue';
import Universities from '@/components/university/Universities.vue';
import Footer from '@/components/hat/Footer.vue';
import Slider from "@/components/Slider.vue";
import MainBanner from "@/components/main/MainBanner.vue";
import ArticleCard from "@/components/main/ArticleCard.vue";
import SubscribeCard from "@/components/main/SubscribeCard.vue";
import Articles from "@/components/main/Articles.vue";

export default {
  name: 'Home',
  components: {
    Articles,
    SubscribeCard,
    ArticleCard,
    MainBanner,
    Slider,
    Navbar,
    Header,
    Reviews,
    Metrics,
    Universities,
    Footer,
  },
};
</script>

<style scoped>
.main {
  background-color: #f5f7fa;
}
</style>
