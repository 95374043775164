<template>
  <div class="button-container">
    <component :is="linkTag" :to="computedLink" :href="computedLink" class="router-link">
      <Button :label="text" class="p-button p-button-primary redirect-button"/>
    </component>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'RedirectButton',
  components: {
    Button,
  },
  props: {
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: 'Перейти',
    }
  },
  computed: {
    computedLink() {
      if (this.to) {
        return {
          path: this.to,
          query: {
            city: this.cityId,
            university: this.universityId,
          },
        };
      } else if (this.href) {
        return this.href;
      }
      return null;
    },
    linkTag() {
      return this.to ? 'router-link' : 'a';
    }
  }
};
</script>

<style scoped>
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.router-link, a {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redirect-button {
  width: 80%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: #426CF5;
}

.redirect-button:hover {
  background-color: #3259C4;
}
</style>
