<template>
  <div className="university-page">
    <div className="university-info">
      <h1 className="university-title">{{ university.name }}</h1>
      <div className="image-container">
        <img :src="university.image" alt="University Image" class="university-image"/>
      </div>
      <p className="university-description">{{ university.description }}</p>
    </div>
    <DormitoryList :dormitories="dormitories"/>
  </div>
</template>

<script>
import DormitoryList from '@/components/dorm/DormList.vue';

export default {
  name: 'UniversityPage',
  components: {
    DormitoryList,
  },
  data() {
    return {
      university: {
        name: 'ITMO University',
        image: require('@/assets/university-icon.png'),
        description: 'ITMO University is a large state university in Saint Petersburg and is one of Russia\'s national research universities. It was founded in 1900.',
      },
      dormitories: [
        {id: 1, name: 'Dormitory 1'},
        {id: 2, name: 'Dormitory 2'},
        {id: 3, name: 'Dormitory 3'},
        {id: 4, name: 'Dormitory 4'},
        {id: 5, name: 'Dormitory 5'},
        {id: 6, name: 'Dormitory 6'},
        {id: 7, name: 'Dormitory 7'},
        {id: 8, name: 'Dormitory 8'},
        {id: 9, name: 'Dormitory 9'},
        {id: 10, name: 'Dormitory 10'},
      ],
    };
  },
};
</script>

<style scoped>
.university-page {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.university-info {
  width: 45%;
}

.university-title {
  font-size: 2.5rem;
  color: #0044cc;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.university-image {
  width: 100%;
  max-width: 300px; /* Ограничение максимальной ширины */
  height: auto;
  aspect-ratio: 1 / 1; /* Сохраняет квадратное соотношение */
  border-radius: 10px;
}

.university-description {
  font-size: 1.2rem;
  color: #666;
  margin-top: 1rem;
}
</style>
