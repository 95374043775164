<template>
  <div class="dormitory-detail-page">
    <header class="header">
      <img src="@/assets/Dorm_logos/logo_ITMO.svg" alt="ITMO Logo" class="logo">
      <button class="back-button" @click="goBack">Вернуться к списку</button>
    </header>
    <main class="main-content">
      <div class="left-column">
        <section class="main-info">
          <h2>Студгородок на Вяземском переулке</h2>
          <p><strong>Общежитие находится на <span class="highlight">{{ dormitory.address }}</span></strong></p>
          <p>В общежитии проживает <span class="highlight">{{ dormitory.residents }} человек</span></p>
          <p>Здесь ответы почти на все вопросы: <a href="#" class="link">Гайд на общежитие</a></p>
        </section>
        <section class="working-hours">
          <h2>График работы</h2>
          <div class="section">
            <h3>Прачечная</h3>
            <p>С 7:30 до 21:30<br>Понедельник-пятница</p>
            <p><strong>Цены:</strong></p>
            <ul>
              <li>Стирка - 100 рублей</li>
              <li>Сушка - 80 рублей</li>
            </ul>
            <p>Оплатить можно только картой<br>Можно выбрать один из представленных режимов стирки</p>
          </div>
          <div class="section">
            <h3>Бельевая</h3>
            <p>С 01.06.23 по 18.08.23</p>
            <p>С 7:30 до 21:30<br>Понедельник-четверг</p>
            <p>С 8:30 до 16:30<br>Пятница</p>
          </div>
          <div class="section">
            <h3>Тренажерный зал</h3>
            <p>С 17:00 до 20:00<br>Понедельник</p>
            <p>С 17:00 до 20:00<br>Среда</p>
            <p>С 16:00 до 19:00<br>Пятница</p>
          </div>
        </section>
      </div>
      <div class="right-column">
        <section class="groups">
          <h2>Группы и беседы общежития</h2>
          <div class="group">
            <h3>Telegram</h3>
            <div class="info-row">
              <p class="info-text">Группа студсовета Вязьмы</p>
              <button class="redirect-button">Перейти</button>
            </div>
            <div class="info-row">
              <p class="info-text">Информационный ресурс ИТМО</p>
              <button class="redirect-button">Перейти</button>
            </div>
          </div>
          <div class="group">
            <h3>Беседы</h3>
            <div class="info-row">
              <p class="info-text">Для всевозможных срочных объявлений</p>
              <button class="redirect-button">Перейти</button>
            </div>
            <div class="info-row">
              <p class="info-text">Здесь можно делать все</p>
              <button class="redirect-button">Перейти</button>
            </div>
            <div class="info-row">
              <p class="info-text">Для покупки и продажи вещей</p>
              <button class="redirect-button">Перейти</button>
            </div>
            <div class="info-row">
              <p class="info-text">Для любых сообщений, кроме спама</p>
              <button class="redirect-button">Перейти</button>
            </div>
            <div class="info-row">
              <p class="info-text">Для настолок</p>
              <button class="redirect-button">Перейти</button>
            </div>
          </div>
          <div class="group">
            <h3>Вконтакте</h3>
            <div class="info-row">
              <p class="info-text">Группа общежития</p>
              <button class="redirect-button">Перейти</button>
            </div>
          </div>
          <div class="group">
            <h3>Контакты</h3>
            <div class="info-row">
              <p class="info-text">Председатель студсовета студгородка</p>
              <div class="button-group">
                <button class="redirect-button">Телеграм</button>
                <button class="redirect-button">Вконтакте</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dormitory: {}
    };
  },
  created() {
    const dormId = this.$route.params.id;
    const dormitories = [
      {id: 1, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1000},
      {id: 2, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1000},
      {id: 3, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1000},
      {id: 4, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1000},
      {id: 5, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1000},
      {id: 6, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1000},
    ];
    this.dormitory = dormitories.find(d => d.id == dormId);
  },
  methods: {
    goBack() {
      this.$router.push('/dorms');
    }
  }
};
</script>

<style scoped>
.dormitory-detail-page {
  padding: 2rem;
  background-color: #F6F8FD;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

.logo {
  height: 5rem;
  margin-bottom: 1rem;
}

.back-button {
  background-color: #4D6A80;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
}

.back-button:hover {
  background-color: #3b5064;
}

.main-content {
  display: flex;
  justify-content: space-between;
}

.left-column, .right-column {
  flex: 1;
}

.left-column {
  margin-right: 1rem;
}

.right-column {
  margin-left: 1rem;
}

.main-info, .working-hours, .groups {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.section {
  margin-bottom: 1rem;
}

.highlight {
  font-weight: bold;
}

.link {
  color: #426CF5;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.group {
  margin-bottom: 1rem;
}

.group h3 {
  margin-bottom: 0.5rem;
}

.group ul {
  list-style-type: none;
  padding: 0;
}

.group ul li {
  margin-bottom: 0.5rem;
}

.info-text {
  color: #6c757d;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  flex: 1;
}

.redirect-button {
  background-color: #426CF5;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.redirect-button:hover {
  background-color: #3259C4;
}

.info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}

/* Specific styles for working hours */
.working-hours p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.working-hours ul {
  margin: 0;
  padding-left: 1.5rem;
}

.working-hours ul li {
  margin-bottom: 0.5rem;
}

.working-hours h3 {
  margin-top: 1rem;
}

.main-info p {
  margin: 0.5rem 0;
  line-height: 1.5;
}
</style>
  