<template>
  <div class="grid justify-between align-center" style="height: 200px;">
    <div class="col" style="background-color: lightblue;">Left</div>
    <div class="col" style="background-color: lightgreen;">Right</div>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  components: {
    Button
  }
}
</script>
