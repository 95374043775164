<template>
  <header class="header">
    <div class="header-frame">
      <div class="logo-frame">
        <router-link to="/">
          <img src="@/assets/header/DorMentorLogo.svg" alt="DorMentor Logo" class="logo-image" />
        </router-link>
      </div>
      <div class="nav-frame">
        <div class="desktop-nav">
          <router-link to="/" class="nav-item" :class="{ 'active-nav-item': $route.path === '/' }">Главная</router-link>
          <router-link to="/dorms" class="nav-item" :class="{ 'active-nav-item': $route.path === '/dorms' }">Общежития</router-link>
          <router-link to="/articles/1" class="nav-item" :class="{ 'active-nav-item': $route.path.includes('/articles') }">Статьи</router-link>
          <router-link to="/about" class="nav-item" :class="{ 'active-nav-item': $route.path === '/about' }">О нас</router-link>
        </div>
        <Button icon="pi pi-bars" class="p-button-rounded p-button-text menu-button" @click="showSidebar = true" />
      </div>
    </div>
    <!-- :closeOnEscape="true" :dismissable="true" для реализации своего крестика, position="full" – занимает весь экран -->
    <Sidebar :visible="showSidebar" @hide="showSidebar = false" position="full" class="mobile-sidebar" :closeOnEscape="true" :dismissable="true" :show-close-icon="false">
      <Button icon="pi pi-times" class="p-button-rounded p-button-text close-button" @click="showSidebar = false" />
      <ul class="mobile-menu">
        <li><router-link to="/" class="nav-item" @click.native="showSidebar = false">Главная</router-link></li>
        <li><router-link to="/dorms" class="nav-item" @click.native="showSidebar = false">Общежития</router-link></li>
        <li><router-link to="/articles/1" class="nav-item" @click.native="showSidebar = false">Статьи</router-link></li>
        <li><router-link to="/about" class="nav-item" @click.native="showSidebar = false">О нас</router-link></li>
      </ul>
    </Sidebar>
  </header>
</template>

<script>
import { ref } from 'vue';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';

export default {
  name: 'Header',
  components: {
    Sidebar,
    Button,
  },
  setup() {
    const showSidebar = ref(false);

    return { showSidebar };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9vh;
  background-color: white;
  border-bottom: 1px solid #eaeaea;
}

.header-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
}

.logo-frame {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 4vh;
  transition: filter 0.3s;
}

.logo-image:hover {
  filter: invert(30%) sepia(99%) saturate(2305%) hue-rotate(204deg) brightness(101%) contrast(96%);
}

.nav-frame {
  display: flex;
  align-items: center;
}

.desktop-nav {
  display: flex;
  gap: 2rem;
}

.nav-item {
  font-size: 1.3rem;
  font-weight: 500;
  color: black;
  text-decoration: none;
  position: relative;
}

.nav-item:hover,
.nav-item.active-nav-item {
  color: #426CF5;
}

.active-nav-item::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #426CF5;
}

.menu-button {
  display: none;
  font-size: 1.5rem;
  border: none;
  color: black;
}

.mobile-sidebar {
  padding: 2rem;
  background-color: #313A51;
}

.close-button {
  position: absolute;
  top: 2rem;
  right: 0.5rem;
  font-size: 1.5rem;
  color: white;
}

.mobile-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #313A51;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-menu li {
  width: 100%;
  text-align: center;
}

.mobile-menu .nav-item {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  display: block;
  padding: 1.5rem 0;
  width: 100%;
}

.mobile-menu .nav-item:hover {
  background-color: #00000033;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .menu-button {
    display: block;
  }
}
</style>