<template>
    <div class="what-we-offer">
      <div class="main-frame">
        <h1 class="title">Что мы предлагаем?</h1>
        <div class="inner-frame">
          <div class="block">
            <img src="@/assets/about/WhatWeOffer/Leaflet.svg" alt="Leaflet Icon" class="icon" />
            <p class="text">Информация о доступных общежитиях каждого ВУЗа</p>
          </div>
          <div class="block">
            <img src="@/assets/about/WhatWeOffer/Time.svg" alt="Time Icon" class="icon" />
            <p class="text">Больше времени на учебу и личное развитие</p>
          </div>
          <div class="block">
            <img src="@/assets/about/WhatWeOffer/Harmony.svg" alt="Harmony Icon" class="icon" />
            <p class="text">Советы по улучшению студенческой жизни в общежитии</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WhatWeOffer'
  }
  </script>
  
  <style scoped>
  .what-we-offer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-bottom: 5vh; /* не прилипаем к другим компонентам */
  }
  
  .main-frame {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6vh;
  }
  
  .title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 4.8vh;
    font-weight: 700;
    line-height: 6.24vh;
    text-align: center;
    margin-bottom: 2vh;
  }
  
  .inner-frame {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2vh;
    flex-wrap: wrap;
  }
  
  .block {
    flex: 1 1 calc(33.33% - 2vh); /* три блока в ряд, при 1080+ */
    height: auto;
    padding: 4vh 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5vh;
    border-radius: 1vh 1vh 1vh 1vh;
    background-color: white;
    box-sizing: border-box; /* без этой строки не сделать @media с calc */
  }
  
  .icon {
    width: 8.5vh;
    height: auto;
  }
  
  .text {
    font-size: 2.4vh;
    font-weight: 400;
    line-height: 3.12vh;
    text-align: center;
    padding: 0 2vh; /* отступы слева и справа для текста внутри блоков */
  }
  
  @media (max-width: 1024px) {
    .block {
      flex: 1 1 calc(50% - 2vh); /* два блока в ряд, один внизу*/
    }
  }
  
  @media (max-width: 768px) {
    .block {
      flex: 1 1 100%; /* три блока в row */
    }
  }
  </style>