<template>
  <div class="article-card" @mouseover="showFullDescription" @mouseleave="showTruncatedDescription">
    <div class="article-image-container">
      <img :src="image" alt="Article Image" class="article-image"/>
      <div class="image-overlay"></div>
    </div>
    <div class="article-content">
      <h3 class="article-title">{{ title }}</h3>
      <p class="article-description">{{ descriptionToShow }}</p>
      <router-link :to="link" class="article-link">ЧИТАТЬ ДАЛЬШЕ</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleCard',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      descriptionToShow: '',
      truncatedDescription: '',
      fullDescription: ''
    }
  },
  mounted() {
    this.truncatedDescription = this.getTruncatedDescription(this.description, 70);
    this.fullDescription = this.getTruncatedDescription(this.description, 200);
    this.descriptionToShow = this.truncatedDescription;
  },
  methods: {
    getTruncatedDescription(description, maxLength) {
      if (description.length > maxLength) {
        return description.slice(0, maxLength) + '...';
      }
      return description;
    },
    showFullDescription() {
      this.descriptionToShow = this.fullDescription;
    },
    showTruncatedDescription() {
      this.descriptionToShow = this.truncatedDescription;
    }
  }
}
</script>

<style scoped>
.article-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
  height: 400px;
  margin: 1rem;
  position: relative;
}

.article-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.article-image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.article-card:hover .article-image {
  opacity: 0.7;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
}

.article-card:hover .image-overlay {
  opacity: 1;
}

.article-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: hidden;
}

.article-title {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.article-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: max-height 0.3s ease-in-out;
}

.article-link {
  color: #426CF5;
  text-decoration: none;
  font-weight: bold;
  margin-top: auto;
}

.article-link:hover {
  text-decoration: underline;
}
</style>
