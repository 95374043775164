<template>
  <div class="grid subscribe-container">
    <div class="col col-md-6 subscribe-content">
      <h2 class="subscribe-title">Подпишись на нас в телеграм и будь в курсе!</h2>
      <p class="subscribe-description">
        Мы — команда DormMentor, наша цель — создать удобное пространство для студентов, где можно обмениваться опытом, задавать вопросы и находить единомышленников!
      </p>
      <RedirectButton href="https://t.me/Dormentor"/>
    </div>
    <div class="col col-md-6 subscribe-image-frame">
      <img src="@/assets/about/ContactUs/IconContact.svg" alt="Subscribe Image" class="subscribe-image"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import RedirectButton from "@/components/button/RedirectButton.vue";

export default defineComponent({
  name: 'SubscribeCard',
  components: {
    RedirectButton,
    Button
  },
  methods: {
    redirectToTelegram() {
      window.open('https://t.me/Dormentor', '_blank');
    }
  }
});
</script>

<style scoped>
.subscribe-container {
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 2rem auto;
  max-width: 90%;
}

.subscribe-content {
  text-align: left;
}

.subscribe-image-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.subscribe-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.subscribe-description {
  font-size: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .subscribe-container {
    flex-direction: column;
    text-align: center;
  }

  .subscribe-content,
  .subscribe-image-frame {
    text-align: center;
  }

  .subscribe-image {
    margin-top: 2rem;
  }
}
</style>
