<template>
  <section class="articles-section">
    <h2 class="section-title">Полезное для тебя</h2>
    <div class="articles-grid">
      <ArticleCard
          v-for="(article, index) in articles"
          :key="index"
          :image="article.image"
          :title="article.title"
          :description="article.description"
          :link="article.link"
      />
    </div>
  </section>
</template>

<script>
import ArticleCard from "@/components/main/ArticleCard.vue";
import UniversityCard from "@/components/university/UniversityCard.vue";

export default {
  name: 'Articles',
  components: {UniversityCard, ArticleCard},
  data() {
    return {
      articles: [
        {
          image: require("@/assets/img_1.png"),
          title: "Как студенту поступить в ВУЗ",
          description: "Поступление в вуз — важный этап в жизни каждого студента. Этот процесс может показаться сложным и запутанным, " +
              "но с правильным подходом и планированием его можно значительно упростить. В этой статье мы рассмотрим основные шаги, " +
              "которые помогут вам успешно поступить в вуз...",
          link: "/article1"
        },
        {
          image: require("@/assets/img_1.png"),
          title: "Как студенту поступить в ВУЗ",
          description: "Пошаговое руководство",
          link: "/article2"
        },
        {
          image: require("@/assets/img_1.png"),
          title: "Как студенту поступить в ВУЗ",
          description: "Пошаговое руководство",
          link: "/article3"
        }
      ]
    };
  }
}
</script>

<style scoped>
.articles-section {
  padding: 2rem;
  background-color: #f5f7fa;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.articles-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
}
</style>
