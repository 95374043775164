import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/AboutPage.vue'
import UniversityPage from "@/views/UniversityPage.vue";
import DormitoryPage from "@/views/DormitoryPage.vue";
import DormitoryListPage from "@/views/DormitoryListPage.vue";
import AuthPage from "@/views/AuthPage.vue";
import DormitoryDetailPage from '@/views/DormitoryDetailPage'
import ArticlePage from "@/views/ArticlePage.vue";
import TestPage from "@/views/TestPage.vue";

const routes = [
    {path: '/', name: 'Home', component: Home},
    {path: '/about', name: 'About', component: About},
    {
        path: '/universities/:id',
        name: 'UniversityPage',
        component: UniversityPage,
        props: true,
    },
    {
        path: '/dorms/:id',
        name: 'DormitoryPage',
        component: DormitoryPage,
        props: true,
    },
    {
        path: '/dorms',
        name: 'DormitoryListPage',
        component: DormitoryListPage,
    },
    {
        path: '/auth',
        name: 'AuthPage',
        component: AuthPage,
    },
    {
        path: '/dormitories/:id',
        name: 'DormitoryDetailPage',
        component: DormitoryDetailPage
    },
    {
        path: '/articles/:id',
        name: 'ArticlePage',
        component: ArticlePage
    },
    {
        path: '/test',
        name: 'TestPage',
        component: TestPage
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
