<template>
  <section class="universities-section">
    <h2 class="section-title">Университеты</h2>
    <div class="universities-grid">
      <UniversityCard
          v-for="university in universities"
          :key="university.id"
          :image="university.image"
          :name="university.name"
          :cityId="university.cityId"
          :universityId="university.id"
       id="university.id"/>
    </div>
  </section>
</template>

<script>
import UniversityCard from './UniversityCard.vue';
import itmoImage from '@/assets/itmo.png';
import hseImage from '@/assets/hse.svg';
import spbuImage from '@/assets/SPBU St Petersburg University.svg'; // замените на фактический путь

export default {
  name: 'Universities',
  components: {
    UniversityCard
  },
  data() {
    return {
      universities: [
        {id: 'university1', name: 'ИТМО', image: itmoImage, cityId: 'city1'},
        {id: 'university2', name: 'ВШЭ', image: hseImage, cityId: 'city2'},
        {id: 'university3', name: 'СПБГУ', image: spbuImage, cityId: 'city3'}
        // Добавьте здесь больше университетов при необходимости
      ]
    }
  }
}
</script>

<style scoped>
.universities-section {
  padding: 2rem;
  background-color: #f5f7fa;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.universities-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
}
</style>
