<template>
  <div class="auth-page">
    <div class="auth-container">
      <div class="toggle-auth">
        <button @click="showLogin = true" :class="{ active: showLogin }">Войти</button>
        <button @click="showLogin = false" :class="{ active: !showLogin }">Регистрация</button>
      </div>
      <div v-if="showLogin" class="auth-form">
        <h2>Войти</h2>
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="login-username">Имя пользователя</label>
            <input type="text" id="login-username" v-model="loginUsername" required />
          </div>
          <div class="form-group">
            <label for="login-password">Пароль</label>
            <input type="password" id="login-password" v-model="loginPassword" required />
          </div>
          <button type="submit">Войти</button>
        </form>
      </div>
      <div v-else class="auth-form">
        <h2>Регистрация</h2>
        <form @submit.prevent="register">
          <div class="form-group">
            <label for="reg-email">Email</label>
            <input type="email" id="reg-email" v-model="registerEmail" required />
          </div>
          <div class="form-group">
            <label for="reg-username">Имя пользователя</label>
            <input type="text" id="reg-username" v-model="registerUsername" required />
          </div>
          <div class="form-group">
            <label for="reg-password">Пароль</label>
            <input type="password" id="reg-password" v-model="registerPassword" required />
          </div>
          <div class="form-group">
            <label for="reg-course">Курс</label>
            <input type="number" id="reg-course" v-model="registerCourse" required />
          </div>
          <button type="submit">Зарегистрироваться</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '../axios';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

export default {
  name: 'AuthPage',
  data() {
    return {
      showLogin: true,
      registerEmail: '',
      registerPassword: '',
      registerUsername: '',
      registerCourse: null,
      loginUsername: '',
      loginPassword: '',
    };
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    return { toast, router };
  },
  methods: {
    async register() {
      try {
        const userDto = {
          userId: null,
          email: this.registerEmail,
          course: this.registerCourse,
          role: 'STUDENT',
        };

        const response = await apiClient.post('/auth/register', userDto, {
          headers: {
            'Username': this.registerUsername,
            'Password': this.registerPassword,
          },
        });

        console.log('Регистрация успешна', response.data);
        this.toast.success('Регистрация успешна!');
        // Перенаправить пользователя после успешной регистрации
        this.router.push('/'); // Перенаправление на домашнюю страницу
      } catch (error) {
        console.error('Ошибка регистрации', error);
        this.toast.error('Ошибка регистрации');
      }
    },
    async login() {
      try {
        const response = await apiClient.post('/auth/login', null, {
          headers: {
            'Username': this.loginUsername,
            'Password': this.loginPassword,
          },
        });

        // Попытка получить заголовок в нижнем регистре
        const jwt = response.headers['authorization'];
        console.log('Авторизация успешна', jwt);
        localStorage.setItem('jwt', jwt);
        this.toast.success('Авторизация успешна!');
        this.router.push('/'); // Перенаправление на домашнюю страницу
      } catch (error) {
        console.error('Ошибка авторизации', error);
        this.toast.error('Ошибка авторизации');
      }
    },
  },
};
</script>

<style scoped>
.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-container {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.toggle-auth {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.toggle-auth button {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.toggle-auth button.active {
  background-color: #007bff;
  color: white;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button[type="submit"] {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
</style>
