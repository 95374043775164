<template>
  <div class="main-banner">
    <div class="text-content">
      <h1>Проверенные общежития в твоём городе</h1>
      <p>Оставь свои переживания позади. Мы тебе все расскажем!</p>
      <button class="btn btn-primary" onclick="location.href='dorms'">Общежития</button>
    </div>
    <div class="image-content">
      <img src="@/assets/main/student.png" alt="Студент" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBanner'
};
</script>

<style scoped>
.main-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 8px;
  flex-wrap: wrap; /* Позволяет элементам переноситься на новую строку при необходимости */
}

.text-content {
  flex: 1;
  max-width: 50%;
  padding: 5%;
}

.text-content h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #426cf5;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #3259c4;
}

.image-content {
  flex: 1;
  max-width: 40%;
  min-width: 300px;
  margin-bottom: -0.5vh;

}

.image-content img {
  max-width: 100%;
  height: auto;
  min-height: 200px;
}

@media (max-width: 768px) {
  .main-banner {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    max-width: 100%;
    text-align: center;
  }

  .image-content {
    max-width: 100%;
    text-align: center;
    margin-top: 1rem;
  }

  .image-content img {
    width: 50%; /* Уменьшаем размер изображения на мобильных устройствах */
    min-width: unset; /* Отменяем минимальную ширину на мобильных устройствах */
    min-height: 150px; /* Устанавливаем минимальную высоту для изображения */
  }
}
</style>
