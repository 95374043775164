<template>
  <div class="metrics-container">
    <div class="metrics-row">
      <div class="metrics-item" v-for="(metric, index) in metrics" :key="index">
        <div class="icon">
          <img :src="metric.icon" :alt="metric.alt"/>
        </div>
        <div class="text">
          <span class="number">{{ metric.number }}<span class="plus">+</span></span>
          <span class="description">{{ metric.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Metrics',
  data() {
    return {
      metrics: [
        {
          icon: require('@/assets/metrics/university.png'),
          alt: 'ВУЗы',
          number: '3',
          description: 'ВУЗов'
        },
        {
          icon: require('@/assets/metrics/dormitory.png'),
          alt: 'Общежития',
          number: '5',
          description: 'Общежитий'
        },
        {
          icon: require('@/assets/metrics/users.png'),
          alt: 'Пользователи',
          number: '3000',
          description: 'Пользователей'
        }
      ]
    };
  }
};
</script>

<style scoped>
.metrics-container {
  width: 100%;
  background-color: #f5f7fa;
  padding: 1rem;
}

.metrics-row {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: nowrap;
}

.metrics-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
}

.icon img {
  width: 15vh;
  height: 15vh;
  margin-bottom: 1rem;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0038E3;
}

.plus {
  margin-left: 0.2rem;
  font-size: 1.5rem;
}

.description {
  font-size: 1rem;
  margin-top: 0.2rem;
}

@media (max-width: 768px) {
  .metrics-row {
    flex-direction: column;
    align-items: center;
  }

  .metrics-item {
    justify-content: center;
  }

  .icon img {
    margin-bottom: 0.5rem;
  }

  .text {
    align-items: center;
    text-align: center;
  }
}
</style>
