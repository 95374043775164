<template>
  <footer class="footer">
    <div class="left-section">
      <div class="logo">
        <img src="@/assets/footer/footer_logo.svg" alt="Dormentor Logo" class="logo-icon">
        <p>Удобное пространство для студентов, где можно обмениваться опытом, задавать вопросы и находить единомышленников</p>
        <div class="social-icons">
          <a href="https://t.me/Dormentor" class="social-icon telegram"></a>
          <a href="https://vk.com/dormentor" class="social-icon vk"></a>
        </div>
      </div>
    </div>
    <div class="center-section">
      <nav class="nav">
        <a href="/" class="nav-item">ГЛАВНАЯ</a>
        <a href="/dorms" class="nav-item">ОБЩЕЖИТИЯ</a>
        <a href="/about" class="nav-item">О НАС</a>
        <a href="/articles" class="nav-item">СТАТЬИ</a>
        <a href="/contacts" class="nav-item">КОНТАКТЫ</a>
      </nav>
    </div>
    <div class="right-section">
      <p>&copy; 2024 Dormentor. Все права защищены.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #313A51;
  color: white;
  font-family: 'Helvetica', sans-serif;
}

.left-section, .center-section, .right-section {
  width: 100%;
  text-align: center;
}

.left-section {
  margin-bottom: 1rem;
}

.logo p {
  margin: 1rem 0;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons .social-icon {
  width: 24px;
  height: 24px;
  margin: 0 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.social-icons .telegram {
  background-image: url('@/assets/footer/telegram-icon.svg');
}

.social-icons .vk {
  background-image: url('@/assets/footer/vk-icon.svg');
}

.center-section {
  margin-bottom: 1rem;
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  margin: 0.5rem 0;
  white-space: nowrap;
}

.nav-item:hover,
.nav-item:active {
  color: #B3B3B3;
}

.right-section {
  align-self: flex-end; /* Выравнивание по правому краю */
  margin-top: auto; /* Перемещает копирайт внизу */
}

.right-section p {
  font-size: 0.75rem;
  color: #B3B3B3;
}

@media (min-width: 915px) {
  .footer {
    flex-direction: row;
    align-items: flex-start;
  }

  .left-section, .center-section, .right-section {
    width: auto;
    text-align: left;
  }

  .left-section {
    margin-bottom: 0;
  }

  .social-icons {
    justify-content: flex-start;
  }

  .center-section {
    flex-grow: 1;
    margin-bottom: 0;
    display: flex;
    justify-content: space-around; /* равномерное распределение пространства */
    width: 100%;
  }

  .nav {
    flex-direction: row;
    justify-content: space-between; /* Разделение элементов по ширине */
    gap: 2rem; /* Увеличиваем промежутки между элементами */
    width: 100%;
  }

  .nav-item {
    margin: 0;
  }

  .right-section {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
    margin-top: auto; /* Перемещает копирайт внизу */
  }

  .right-section p {
    text-align: right;
  }
}
</style>