<template>
  <div class="articles-page">
    <div class="main-article">
      <Article :article="mainArticle" />
    </div>
    <div class="sidebar">
      <h2 class="sidebar-title">Смотрите также</h2>
      <div class="sidebar-articles">
        <SidebarArticle
            v-for="article in sidebarArticles"
            :key="article.id"
            :article="article"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Article from "@/article/Article.vue";
import SidebarArticle from "@/article/SidebarArticle.vue";

export default {
  name: 'ArticlePage',
  components: {
    Article,
    SidebarArticle,
  },
  data() {
    return {
      mainArticle: {
        id: 1,
        title: 'Как студенту поступить в ВУЗ',
        image: require('@/assets/img_1.png'),
        content: `
          <h3>1. Определитесь с целями и специальностью</h3><br/>
          <p>Первым шагом является понимание того, какую специальность вы хотите изучать. Для этого ответьте на следующие вопросы:</p><br/>
          <ul>
            <li>Какие предметы вам нравятся и в каких вы успешны?</li>
            <li>Какие профессии вас интересуют?</li>
            <li>Какой образ жизни вы хотите вести в будущем?</li>
          </ul><br/>
          <p>Проведите исследование различных специальностей, узнайте, какие профессии востребованы на рынке труда, и выберите те, которые наиболее соответствуют вашим интересам и целям.</p><br/>
          <h3>2. Изучите требования вузов</h3><br/>
          <p>Каждый вуз имеет свои требования для поступления, поэтому важно ознакомиться с ними заранее. Обычно эти требования включают:</p><br/>
          <ul>
            <li>Средний балл аттестата</li>
            <li>Результаты ЕГЭ или других вступительных экзаменов</li>
            <li>Портфолио или творческие работы (для некоторых специальностей)</li>
            <li>Дополнительные испытания или собеседования</li>
          </ul><br/>
          <p>Создайте список вузов, которые вас интересуют, и подробно изучите их требования.</p><br/>
          <h3>3. Подготовьтесь к экзаменам</h3><br/>
          <p>Для успешного поступления в вуз необходимо хорошо сдать вступительные экзамены. Основные шаги подготовки включают:</p><br/>
          <ul>
            <li>Создание плана подготовки и изучение сроков экзаменов</li>
            <li>Регулярные занятия и самоподготовка</li>
            <li>Посещение курсов или занятий с репетитором</li>
            <li>Решение пробных тестов и анализ своих ошибок</li>
          </ul><br/>
          <p>Не забывайте делать перерывы и заботиться о своем здоровье, чтобы избежать переутомления.</p>
        `,
      },
      sidebarArticles: [
        { id: 2, title: 'Как студенту поступить в ВУЗ', image: '@/assets/sidebar-article-1.jpg', description: 'Пошаговое руководство' },
        { id: 3, title: 'Как студенту поступить в ВУЗ', image: '@/assets/sidebar-article-2.jpg', description: 'Пошаговое руководство' },
        { id: 4, title: 'Как студенту поступить в ВУЗ', image: '@/assets/sidebar-article-3.jpg', description: 'Пошаговое руководство' },
        { id: 5, title: 'Как студенту поступить в ВУЗ', image: '@/assets/sidebar-article-4.jpg', description: 'Пошаговое руководство' },
        { id: 6, title: 'Как студенту поступить в ВУЗ', image: '@/assets/sidebar-article-5.jpg', description: 'Пошаговое руководство' },
      ],
    };
  },
};
</script>

<style scoped>
.articles-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #F6F8FD;
  font-family: "Helvetica", sans-serif;
}

.main-article {
  width: 70%;
}

.sidebar {
  width: 25%;
}

.sidebar-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #4C6A7F;
}

.sidebar-articles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar-articles .read-more {
  color: #4C6A7F;
}
</style>