<template>
  <div class="page-container">
    <SelectorsDorms @update-dormitories="updateDormitories" />
    <DormList :dormitories="filteredDormitories" />
  </div>
</template>

<script>
import SelectorsDorms from '@/components/dorm/SelectorsDorms.vue';
import DormList from '@/components/dorm/DormList.vue';

export default {
  components: {
    SelectorsDorms,
    DormList,
  },
  data() {
    return {
      dormitories: [
        { id: 1, name: 'Общежитие ИТМО', address: 'Вяземский переулок, 5-7', residents: 1769, cityId: 'city1', universityId: 'university1' },
        { id: 2, name: 'Общежитие ИТМО', address: 'ул. Ленсовета, д. 23, лит. А', residents: 585, cityId: 'city1', universityId: 'university1' },
        { id: 3, name: 'Общежитие ИТМО', address: 'Альпийский пер., д.15, к. 2, лит. А', residents: 540, cityId: 'city1', universityId: 'university1' },
        { id: 4, name: 'Общежитие № 2', address: 'ул. Кораблестроителей, 20, корп. 2', residents: 500, cityId: 'city1', universityId: 'university2' },
        { id: 4, name: 'Общежитие ДСЯ', address: 'Литовский бульвар, д. 6, корп. 1.', residents: 500, cityId: 'city3', universityId: 'university5' },
        { id: 6, name: 'Общежитие ДСК', address: 'ул. Кравченко, д. 7.', residents: 1200, cityId: 'city3', universityId: 'university5' },
        { id: 4, name: 'Общежитие ЮУ ИУБиП', address: 'Улица Пушкина, 10', residents: 500, cityId: 'city2', universityId: 'university2' },
        { id: 5, name: 'Общежитие ЮФУ', address: 'Проспект Ленина, 25', residents: 700, cityId: 'city2', universityId: 'university3' },
      ],
      filteredDormitories: [],
    };
  },
  mounted() {
    this.filteredDormitories = this.dormitories;
    this.handleQueryParams();
  },
  methods: {
    updateDormitories(cityId, universityId) {
      this.filteredDormitories = this.dormitories.filter(dormitory => dormitory.cityId === cityId && dormitory.universityId === universityId);
    },
    handleQueryParams() {
      const query = this.$route.query;
      if (query.city && query.university) {
        this.updateDormitories(query.city, query.university);
      }
    }
  },
  watch: {
    '$route.query': 'handleQueryParams'
  }
};
</script>

<style scoped>
.page-container {
  background-color: #f7f8fd; /* цвет бэкграунда странички */
  padding: 2rem;
}
</style>
