<template>
  <div class="sidebar-article">
    <h3 class="sidebar-article-title">{{ article.title }}</h3>
    <p class="sidebar-article-description">{{ article.description }}</p>
    <a :href="'/articles/' + article.id" class="sidebar-article-link">Читать дальше</a>
  </div>
</template>

<script>
export default {
  name: 'SidebarArticle',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.sidebar-article {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-article-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.sidebar-article-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
}

.sidebar-article-link {
  font-size: 14px;
  color: #0038E3;
  text-decoration: none;
}

.sidebar-article-link:hover {
  text-decoration: underline;
}
</style>
